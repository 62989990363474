import React, { useEffect, useState } from "react";
import axiosConfig from "../Service/axiosConfig"
import "../Pages/IsPro.css"

export const IsProComponent = ({ handleScreen }) => {
    const [userPlan, setUserPlan] = useState(null);
    const fetchUserPlan = async () => {
        let profile_data = JSON.parse(localStorage.getItem("profile_data"))
        try {
            const response = await axiosConfig.get(`/accounts/user-plans/?profile=${profile_data?.profile_id}`);
            if (response.data.results.length > 0) {
                setUserPlan(response.data.results[0]);  // Set the first item of the list
            } else {
                setUserPlan(null);  // Handle the case where there are no user plans
            }
        } catch (error) {
            console.error("Error fetching user plans:", error);
        }
    };
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        fetchUserPlan();
    }, []);

    return (
        <div className='pro-upgrade-auto'>
            <header className="upgrade-header">
                <div className="pro_upgrade_title">
                   <img src={require("./images/proicon.png")} alt="icon"/>
                   <img src={require("./images/protext.png")} alt="member" className="pro_member_text" />
                </div>
                {/* <h3>Welcome, Pro Member!</h3> */}
                <p className="pro_exclusive">Enjoy your exclusive features!</p>
                <p className='hide-mobile pro_premium_member'>
                    As a Pro member, you’ll get access to premium features that can skyrocket your career in the entertainment industry.
                </p>
            </header>
            <section className='pro-container pro-hide-for-desktop'>
                <h2 className='pro-upgrade-h2'>Why Upgrade?</h2>
                <p>
                    As a Pro member, you’ll get access to premium features that can skyrocket your career in the entertainment industry.
                </p>
            </section>
            <section className="container">
                <h2 className='upgrade-h2'>Here’s What You Get</h2>
                <ul className="feature-list">
                    <li> <p>Unlimited Audition Applications</p>  <span>🔓</span> </li>
                    <li> <p>Priority in Search Results</p>  <span>🔍</span> </li>
                    <li><p>Exclusive Access to Premium Auditions</p>  <span>🎬</span> </li>
                    {/* <li>📧 Send Unlimited Messages</li> */}
                    <li><p>No Ads for a Seamless Experience</p>  <span>🚫</span> </li>
                    <li><p> Early Access to New Features</p> <span>🚀</span> </li>
                </ul>
            </section>
            {userPlan && (
                <section className="cta-section no-bg-padding">
                    <section className="pro-cta-section">
                        <h2>Congratulations! You're already a Pro member. Enjoy your exclusive benefits</h2>
                    </section>
                    <div className="plan-details">
                        <div className="plan-row">
                            <span className="label">Start Date:</span>
                            <span className="value">{new Date(userPlan.start_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                        </div>
                        <div className="plan-row">
                            <span className="label">End Date:</span>
                            <span className="value">{new Date(userPlan.end_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                        </div>
                        <div className="plan-row status-row">
                            <span className="label">Status:</span>
                            <span className={`value status ${userPlan.is_active ? 'active' : 'inactive'}`}>
                                {userPlan.is_active ? 'Active' : 'Inactive'}
                            </span>
                        </div>
                        <div className="action-buttons">
                            <button className={`btn btn-block ${userPlan.is_active ? 'action-buttons' : 'btn-secondary'}`} disabled={userPlan.is_active}>
                                {userPlan.is_active ? 'Active' : 'Upgrade Now'}
                            </button>
                        </div>
                    </div>
                </section>

            )}
        </div>
    );
};
