import React, { useState, useEffect } from "react";
import { Navbar, Footer, Stories, Follow } from "../Components";
import { useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PostsPageGrid } from "./PostsPageGrid";
import "./Pages.css"
import axiosConfig from "../Service/axiosConfig";
import Modal from 'react-bootstrap/Modal';
import { EditProfile, EditDp, DisplayBio, MyProjects } from "../Components";
import CustomTop from "./CustomTop";
import { MdOutlineModeEditOutline, MdOutlineMoreVert, MdOutlineVerified, MdVerified } from "react-icons/md";
import { Shortlist } from "../Components/ShortList/ShortList";
import { FollowersPop, FollowingPop } from "../Components/Follow";
import { BiBadgeCheck, BiSolidBadgeCheck } from "react-icons/bi";
import { ShortlistPops } from "../Components/ShortList";
import { useAuth } from '../Utils/AuthContext';
import { CraftsModal } from "./CraftsModal";
import { UpgradePage } from "./UpgradePage";
import { ProfileAudition } from "./ProfileAudition";
import { Helmet } from "react-helmet";
import { GrEdit } from "react-icons/gr";
import { RiShareForwardFill } from "react-icons/ri";


const ProfilePage = () => {
    const { isLoggedIn, is24Craft } = useAuth();
    let { hcc_id } = useParams();
    let can_edit_profile = false
    if (!hcc_id) {
        hcc_id = localStorage.getItem("hcc_id")
    }
    if (hcc_id === localStorage.getItem("hcc_id")) {
        can_edit_profile = true
    }
    const profile_data = JSON.parse(localStorage.getItem("profile_data"))
    const frames_24 = localStorage.getItem('frames_24');
    const [EditProfileFor, setEditProfileFor] = useState("profile");
    const [EditProfileShow, setEditProfileShow] = useState(false);
    const [Follower, setFollower] = useState(false);
    const [shortlist, setshortlist] = useState(false);
    const [Following, setFollowing] = useState(false);
    const handleEditProfileClose = () => setEditProfileShow(false);
    const [loading, setLoading] = useState(false)
    const [ProfileData, SetProfileData] = useState({})
    const [showApply, setshowApply] = useState(false)
    const [profilePic, setProfilepic] = useState(false)
    const [UpgradeShow, setUpgradeShow] = useState(false)
    const [ScreenVisbile, setScreenVisbile] = useState("plans")
    function handleLoginEvent() {
        setProfilepic(false)
        setEditProfileShow(false);
        GetData()
    }
    const GetData = async () => {
        setLoading(true)
        if (hcc_id) {
            try {
                const responce = await axiosConfig.get(`/accounts/user-profiles/?hcc_id=${hcc_id}`)
                SetProfileData(responce.data.results[0])
                if (hcc_id !== localStorage.getItem("hcc_id")) {
                    ProfileView(responce.data.results[0].id)
                }
                setLoading(false)
            }
            catch (error) {
                console.log(error)
                setLoading(false)
            }
        } else {
            try {
                const responce = await axiosConfig.get(`/accounts/user-profiles-all/${profile_data?.profile_id}/`)
                SetProfileData(responce.data)
                if (hcc_id !== localStorage.getItem("hcc_id")) {
                    ProfileView(responce.data.id)
                }
                setLoading(false)
            }
            catch (error) {
                console.log(error)
                setLoading(false)
            }
        }
    }
    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };
    const handleprofilpicclose = () => {
        setProfilepic(false)
        GetData()
    }
    useEffect(() => {
        let profile_data = JSON.parse(localStorage.getItem("profile_data"))
        if (hcc_id === localStorage.getItem("hcc_id") && profile_data?.profile_pic === null) {
            setProfilepic(true)
        }
    }, [])
    useEffect(() => {
        GetData()
    }, [hcc_id])
    const copyToClipboard = async (copy_item) => {
        if (copy_item === "profile") {
            console.log(ProfileData)
            try {
                await navigator.clipboard.writeText(`${ProfileData.user.first_name} - ${ProfileData.designation} - ${calculateAge(ProfileData.dob)} years - ${ProfileData.state_city.city}(${ProfileData.state_city.state}) https://api.hyderabadcastingclub.com/share/profile/${ProfileData.hcc_id}/`);
                console.log('Text copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy text to clipboard:', err);
            }
        }
    };
    const shareContent = async (share_to) => {
        try {
            let text = `https://api.hyderabadcastingclub.com/share/profile/${ProfileData.hcc_id}/`
            const shareUrl = encodeURIComponent(text);

            if (share_to === "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to === "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    await navigator.share({
                        title: 'Share Post',
                        text: `https://api.hyderabadcastingclub.com/share/profile/${ProfileData.hcc_id}/`,
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };
    const calculateAge = (dob) => {
        const dobDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
        return age;
    };
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate('/request-verifications');
    };
    const ProfileView = async (id) => {
        try {
            const responce = await axiosConfig.get(`/accounts/user-profiles/${id}/viewed/`)
            console.log(responce)
        }
        catch (error) {
            console.log(error);
        }
    }
    const handleShow = () => {
        if (profile_data && profile_data.profile_pic === null || "") {
            setProfilepic(true)
        } else {
            setshowApply(true)
        }
    }
    const handleClose = () => {
        setshowApply(false)
        GetData()
    }
    const HandleUpgrade = () => {
        setUpgradeShow(true)
    }
    const handleUpgradeClose = () => {
        setUpgradeShow(false)
        setScreenVisbile("plans")
    }
    return (
        <>
            <Helmet>
                <title>{`${ProfileData?.user?.first_name} ${ProfileData?.user?.last_name}`}</title>
                <meta name="description" content={`${ProfileData?.user?.first_name} ${ProfileData?.user?.last_name}, ${ProfileData?.profession !== null ? ProfileData?.profession : ""}`} data-react-helmet="true" />
                <meta name="keywords" content={`${ProfileData?.user?.first_name} ${ProfileData?.user?.last_name}, ${ProfileData?.profession !== null ? ProfileData?.profession : ""}`} data-react-helmet="true" />
                <meta property="og:keywords" content={`${ProfileData?.user?.first_name} ${ProfileData?.user?.last_name}, ${ProfileData?.profession !== null ? ProfileData?.profession : ""}`} data-react-helmet="true" />
                <meta property="og:title" content={`${ProfileData?.user?.first_name} ${ProfileData?.user?.last_name}`} data-react-helmet="true" />
                <meta property="og:image" content={ProfileData?.profile_pic} data-react-helmet="true" />
                <meta property="og:description" content={`${ProfileData?.user?.first_name} ${ProfileData?.user?.last_name}`} data-react-helmet="true" />
            </Helmet>
            <CustomTop />
            <div className="main-container profile-page">
                <Navbar />
                <div className="content-area">
                    {loading ? (
                        <div className="loader-container">
                            <img src={require('./images/loader.gif')} alt="Loading..." />
                        </div>
                    ) : (
                        <div>
                            <div className="profile-header">
                                <div className="row m-0 d-flex align-items-center justify-content-center mr-4">
                                    <div className="col-3">
                                        {can_edit_profile ?
                                            <div className="editabledp">
                                                <img src={ProfileData.profile_pic ? ProfileData.profile_pic : require('../Components/Post/images/male.jpg')} className="dp" alt={ProfileData.full_name} />
                                                <div class="middle">
                                                    <div class="text" onClick={() => {
                                                        setEditProfileShow(true);
                                                        setEditProfileFor("dp");
                                                    }}>
                                                        <MdOutlineModeEditOutline size={30} />
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <img src={ProfileData.profile_pic ? ProfileData.profile_pic : require('../Components/Post/images/male.jpg')} className="dp" alt={ProfileData.full_name} />
                                        }
                                    </div>
                                    <div className="col-9">
                                        <div className="profile-details">
                                            <div className="profile-info">
                                                <div className="model-titles">
                                                    <p className="profile_header">{ProfileData.full_name} {ProfileData.is_celebrity ? <MdVerified color="#019bf9" className="verified_member" /> : ProfileData.is_verified && <MdOutlineVerified size={12} color="#808080" />}</p>
                                                    {can_edit_profile ? (
                                                        <>
                                                            <div className="profile_hideformobile">
                                                                <button class="btn btn-default profile_button d-flex align-items-center gap-2" href="#"
                                                                    onClick={() => {
                                                                        setEditProfileShow(true);
                                                                        setEditProfileFor("profile");
                                                                    }}><GrEdit /> Edit Profile</button>
                                                                <div className="dropdown">
                                                                    <button class="btn btn-default profile_button d-flex align-items-center gap-2" data-bs-toggle="dropdown" style={{ color: "black" }}><RiShareForwardFill size={22} />Share Profile</button>
                                                                    {/* <MdOutlineMoreVert className="more-icon" data-bs-toggle="dropdown" /> */}
                                                                    <ul className="dropdown-menu">
                                                                        <li><a className="dropdown-item" onClick={() => copyToClipboard("profile")} href="#!">Copy Profile</a></li>
                                                                        <li><a className="dropdown-item" onClick={() => shareContent("share")} href="#!">Share</a></li>
                                                                        {!can_edit_profile &&
                                                                            <>
                                                                                <li><a className="dropdown-item" href="#!">Report</a></li>
                                                                                <li><a className="dropdown-item" href="#!">Block</a></li>
                                                                            </>}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="hide-mobile">
                                                                <div className="profile_follows">
                                                                    {/* <button class="btn btn-default profile_button"><Follow FollowId={ProfileData.id} /></button> */}
                                                                    {/* <button className="btn btn-default profile_button">Message</button> */}
                                                                    {frames_24?.split(',')?.some(role =>
                                                                        ["Executive Producer", "Casting Director", "Producer", "Director", "Associate Director", "Co-Director", "Assistant Director", "Production Manager"].includes(role.trim())
                                                                    ) &&
                                                                        <div>
                                                                            <Shortlist ShortlistId={ProfileData.id} />
                                                                        </div>
                                                                    }
                                                                    <Follow FollowId={ProfileData.id} />
                                                                    {/* <div className="dropdown">
                                                                        <MdOutlineMoreVert className="more-icon" data-bs-toggle="dropdown" />
                                                                        <ul className="dropdown-menu">
                                                                            <li><a className="dropdown-item" onClick={() => copyToClipboard("profile")} href="#!">Copy Profile</a></li>
                                                                            <li><a className="dropdown-item" onClick={() => shareContent("share")} href="#!">Share</a></li>
                                                                            {!can_edit_profile &&
                                                                                <>
                                                                                    <li><a className="dropdown-item" href="#!">Report</a></li>
                                                                                    <li><a className="dropdown-item" href="#!">Block</a></li>
                                                                                </>}
                                                                        </ul>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="dropdown">
                                                                <MdOutlineMoreVert className="more-icon" data-bs-toggle="dropdown" />
                                                                <ul className="dropdown-menu">
                                                                    <li><a className="dropdown-item" onClick={() => copyToClipboard("profile")} href="#!">Copy Profile</a></li>
                                                                    <li><a className="dropdown-item" onClick={() => shareContent("share")} href="#!">Share</a></li>
                                                                    {!can_edit_profile &&
                                                                        <>
                                                                            <li><a className="dropdown-item" href="#!">Report</a></li>
                                                                            <li><a className="dropdown-item" href="#!">Block</a></li>
                                                                        </>}
                                                                </ul>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="about-profile">
                                                {/* {can_edit_profile && <button class="btn btn-default d-flex align-items-center gap-2 mb-2" href="#" onClick={HandleUpgrade}><FaCrown color="#daa520" />{JSON.parse(localStorage.getItem("is_pro")) ? "Pro Member" : "Upgrade"}</button>} */}
                                                {/* {can_edit_profile && <button class="btn btn-default d-flex align-items-center gap-2 mb-2" onClick={handleNavigation}><MdVerified size={22} color="#019bf9" />Verified Now</button>} */}
                                                <p className="profile_des">
                                                    <b className="profile_profession"> {ProfileData.hcc_id} : </b>{ProfileData.designation} {can_edit_profile && <button class="btn btn-default" onClick={handleShow}><GrEdit /></button>}
                                                </p>
                                                <p className="profile_age"><b className="profile_agetext">D.O.B : </b>{ProfileData.dob && <>
                                                    {ProfileData.dob} ({calculateAge(ProfileData.dob)} Y) </>}
                                                </p>
                                            </div>
                                            <div className="followers-info">
                                                <p className="follow-post"><b className="profile_interet">{ProfileData.total_posts}</b> Posts</p>
                                                <p className="follow-post" onClick={() => setFollower(true)}><b className="profile_interet">{ProfileData.followers ? ProfileData.followers : 0}</b> Followers</p>
                                                <p className="follow-post" onClick={() => setshortlist(true)}><b className="profile_interet">{ProfileData.shortlists ? ProfileData.shortlists : 0}</b> Shortlist</p>
                                                <p className="follow-post"><b className="profile_interet">{ProfileData.views ? ProfileData.views : 0}</b> Views</p>
                                                {can_edit_profile &&
                                                    <p className="follow-post" onClick={() => setFollowing(true)}><b className="profile_interet">{ProfileData.following ? ProfileData.following : 0}</b> Following</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row  hide-for-desktop">
                                    <div className="followers-info">
                                        <p className="follow-post"><b className="profile_interet">{ProfileData.total_posts}</b> Posts</p>
                                        <p className="follow-post" onClick={() => setFollower(true)}><b className="profile_interet">{ProfileData.followers ? ProfileData.followers : 0}</b> Followers</p>
                                        <p className="follow-post" onClick={() => setshortlist(true)}><b className="profile_interet">{ProfileData.shortlists ? ProfileData.shortlists : 0}</b> ShortList</p>
                                        <p className="follow-post"><b className="profile_interet">{ProfileData.views ? ProfileData.views : 0}</b> Views</p>
                                    </div>
                                    {can_edit_profile ? (<>
                                        <div className="profile_hidefordesktop">
                                            <button class="btn btn-default profile_button d-flex align-items-center gap-2" href="#" onClick={() => {
                                                setEditProfileShow(true);
                                                setEditProfileFor("profile");
                                            }}><GrEdit /> Edit Profile</button>
                                            <div className="dropdown">
                                                <button class="btn btn-default profile_button d-flex align-items-center gap-2" data-bs-toggle="dropdown" style={{ color: "black" }}><RiShareForwardFill size={22} />Share Profile</button>
                                                {/* <MdOutlineMoreVert className="more-icon" data-bs-toggle="dropdown" /> */}
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" onClick={() => copyToClipboard("profile")} href="#!">Copy Profile</a></li>
                                                    <li><a className="dropdown-item" onClick={() => shareContent("share")} href="#!">Share</a></li>
                                                    {!can_edit_profile &&
                                                        <>
                                                            <li><a className="dropdown-item" href="#!">Report</a></li>
                                                            <li><a className="dropdown-item" href="#!">Block</a></li>
                                                        </>}
                                                </ul>
                                            </div>
                                        </div>
                                    </>) : (<>
                                        <div className="profile_mobile_follows">
                                            <div className="profile_follows">
                                                {/* <button class="btn btn-default profile_button d-flex align-items-center justify-content-center"> */}
                                                <Follow FollowId={ProfileData.id} />
                                                {/* </button> */}
                                                {/* <button className="btn btn-default profile_button">Message</button> */}
                                                {frames_24?.split(',')?.some(role =>
                                                    ["Executive Producer", "Casting Director", "Producer", "Director", "Associate Director", "Co-Director", "Assistant Director", "Production Manager"].includes(role.trim())
                                                ) &&
                                                    <div>
                                                        <Shortlist ShortlistId={ProfileData.id} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>)}
                                </div>
                            </div>
                            {hcc_id !== localStorage.getItem("hcc_id") || localStorage.getItem('is_24_craft') !== null ?
                                <>
                                    <div className="stories-container">
                                        <Stories />
                                    </div>
                                    <div className="profile-tablinks">
                                        <ul className="nav custom-nav-tabs">
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                                                    onClick={() => handleTabClick(0)}
                                                >
                                                    GALLERY
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                                                    onClick={() => handleTabClick(1)}
                                                >
                                                    BIO DATA
                                                </button>
                                            </li>
                                            <li className="nav-item">
                                                <button
                                                    className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                                                    onClick={() => handleTabClick(2)}
                                                >
                                                    PROJECTS
                                                </button>
                                            </li>
                                            {ProfileData?.designation?.split(',')?.some(role =>
                                                ["Executive Producer", "Casting Director", "Producer", "Director", "Associate Director", "Co-Director", "Assistant Director", "Production Manager"].includes(role.trim())
                                            ) &&
                                                <li className="nav-item">
                                                    <button
                                                        className={`nav-link ${activeTab === 3 ? 'active' : ''}`}
                                                        onClick={() => handleTabClick(3)}
                                                    >
                                                        AUDITIONS
                                                    </button>
                                                </li>
                                            }
                                        </ul>
                                    </div>

                                    <div className="tab-content">
                                        <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`}>
                                            <div className="posting-container">
                                                <PostsPageGrid profile_id={ProfileData.id} can_edit_profile={can_edit_profile} />
                                            </div>
                                        </div>
                                        <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}>
                                            {activeTab === 1 &&
                                                <div className="display-bio">
                                                    <DisplayBio ProfileData={ProfileData} can_edit_profile={can_edit_profile} />
                                                </div>
                                            }
                                        </div>
                                        <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`}>
                                            <div className="projects-container">
                                                {activeTab === 2 &&
                                                    <div className="display-projects">
                                                        <MyProjects ProfileData={ProfileData} can_edit_profile={can_edit_profile} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`}>
                                            <div className="projects-container">
                                                {activeTab === 3 &&
                                                    <div className="display-projects">
                                                        <ProfileAudition ProfileData={ProfileData} can_edit_profile={can_edit_profile} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </>
                                :
                                <div className="no-profile">
                                    <button className="btn btn-default" onClick={handleShow}>Select Your Department</button>
                                </div>
                            }
                            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={EditProfileShow} onHide={handleEditProfileClose} className="edit-profile-popup">
                                <Modal.Header closeButton>
                                    <Modal.Title>Edit Profile</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <>
                                        {EditProfileFor === "dp" ?
                                            <EditDp ProfileData={ProfileData} handleLoginEvent={handleLoginEvent} showApply={showApply} setshowApply={setshowApply} />
                                            :
                                            <EditProfile ProfileData={ProfileData} handleLoginEvent={handleLoginEvent} />
                                        }
                                    </>
                                </Modal.Body>
                            </Modal>
                        </div>
                    )}
                </div>
                {Follower === true &&
                    <FollowersPop Follower={Follower} setFollower={setFollower} Following={Following} setFollowing={setFollowing} ProfileData={ProfileData} />
                }
                {Following === true &&
                    <FollowingPop Following={Following} setFollowing={setFollowing} ProfileData={ProfileData} />
                }
                {shortlist === true &&
                    <ShortlistPops shortlist={shortlist} setshortlist={setshortlist} ProfileData={ProfileData} />
                }
                {showApply &&
                    <CraftsModal showApply={showApply} setshowApply={setshowApply} handleClose={handleClose} />
                }
                {UpgradeShow &&
                    <UpgradePage UpgradeShow={UpgradeShow} setUpgradeShow={setUpgradeShow} ScreenVisbile={ScreenVisbile} setScreenVisbile={setScreenVisbile} handleUpgradeClose={handleUpgradeClose} />
                }
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={profilePic} onHide={handleprofilpicclose} className="edit-profile-popup">
                    <Modal.Header closeButton>
                        <Modal.Title>Add your profile to get started</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditDp handleLoginEvent={handleprofilpicclose} showApply={showApply} setshowApply={setshowApply} />
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </>
    )

}
export { ProfilePage }