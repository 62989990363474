import React, { useState, useEffect } from 'react';
import { Footer, Navbar, PopupModal } from '../Components';
import CustomTop from './CustomTop';
import { FormParser } from '../Components/FormParser/FormParser';
import Masters from "../Components/masters.json"
import axiosConfig from "../Service/axiosConfig"
import { MdCheckCircle, MdCheckCircleOutline, MdOutlineDelete, MdOutlineEdit, MdRadioButtonUnchecked } from "react-icons/md";
import Select from 'react-select';

const VerifiedPage = () => {
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const hcc_id = localStorage.getItem('hcc_id');
    const [VerifyId, setVerifyId] = useState(null)
    const [step, setStep] = useState(1)
    const [Basicdetails, setBasicDetails] = useState(true)
    const [Professionaldetails, setProfessionalDetails] = useState(false)
    const [Status, setStatus] = useState(false)
    const [StatusData, setStatusData] = useState({})
    const [tabledata, settabledata] = useState([])
    const [desigdata, setdesigdata] = useState({})
    const [Data, setData] = useState({})
    const [roles, setRoles] = useState([])
    const [popuptype, setpopuptype] = useState("")
    const [Error, seterror] = useState("")
    const getRoles = async () => {
        try {
            const responce = await axiosConfig.get(`/masters/role-24-frames/?page_size=1000`)
            console.log(responce)
            setRoles(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    const GetprofileData = async () => {
        try {
            const responce = await axiosConfig.get(`/accounts/user-profiles/?hcc_id=${hcc_id}`)
            const responce_data = responce.data.results[0]
            console.log(responce)
            setData((prev) => ({
                ...prev,
                surname: responce_data?.user.last_name,
                name: responce_data?.user.first_name,
                email: responce_data?.user.email,
                gender: responce_data?.gender,
                dob: responce_data?.dob,
                mobile_number: responce_data?.mobile_number
            }))
        }
        catch (error) {
            console.log(error)
        }
    }
    const GetEditData = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/verified-profile/?status=&profile=${profile_data.profile_id}`);
            console.log(response.data.results[0]);
            setData(response.data.results[0])
            setStatusData(response.data.results[0])
            setVerifyId(response.data.results[0].id)
            if (response.data.results.length > 0) {
                setStep(3)
                setStatus(true)
                setBasicDetails(false)
            }
        } catch (error) {
            console.log(error);
        }
    }
    const formSubmit = (FormData) => {
        console.log(FormData, Data)
        seterror("")
        const Data_to_patch = {
            "surname": FormData.surname,
            "name": FormData.name,
            "email": FormData.email,
            "gender": FormData.gender,
            "dob": FormData.dob,
            "present_address": FormData.present_address,
            "permanent_address": FormData.permanent_address,
            "govt_id_proof": FormData.govt_id_proof,
            "present_state": FormData.present_state,
            "present_city": FormData.present_city,
            "permanent_state": FormData.permanent_state,
            "permanent_city": FormData.permanent_city,
            "mobile_number": FormData.mobile_number,
        }
        if (FormData.id) {
            axiosConfig.patch(`/accounts/verified-profile/${FormData.id}/`, Data_to_patch)
                .then(res => {
                    console.log(res)
                    setStep(2)
                    setpopuptype("success")
                    setProfessionalDetails(true);
                    setBasicDetails(false);
                    setStatus(false);
                    GetData()
                    getRoles()
                }).catch(error => {
                    console.log(error.response)
                    if (error.response.data.mobile_number.includes("Invalid Number")) {
                        seterror("Mobile Number Consists of ten digits");
                        setpopuptype("error")
                    }
                    setpopuptype("error")
                })
        } else {
            axiosConfig.post("/accounts/verified-profile/", FormData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(res => {
                    console.log(res)
                    setStep(2)
                    setpopuptype("success")
                    setProfessionalDetails(true);
                    setBasicDetails(false);
                    setStatus(false);
                    GetData()
                    getRoles()
                }).catch(error => {
                    console.log(error.response)
                    console.log(error.response.data.mobile_number)
                    if (error.response.data.mobile_number.includes("Invalid Number")) {
                        seterror("Mobile Number Consists of ten digits");
                        setpopuptype("error")
                    }
                    setpopuptype("error")
                })
        }
    }
    const HandleChange = (e) => {
        e.preventDefault();
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        setdesigdata({ ...desigdata, [name]: value })
    }
    const handleSelectChange = (selectedOption, name) => {
        setdesigdata({ ...desigdata, [name]: selectedOption?.value ? selectedOption?.value : "" });
    }
    const handleFileChange = (e) => {
        e.preventDefault();
        const name = e.target.name
        const file = e.target.files[0];
        console.log(file)
        setdesigdata({ ...desigdata, [name]: file })
    };
    const formSubmit1 = (FormData) => {
        console.log(StatusData, "desigdata", desigdata)
        const data_to_post = {
            ...desigdata,
            verified_profile: StatusData.id
        }
        if (desigdata.id) {
            axiosConfig.patch(`/accounts/verified-profile-designations/${desigdata.id}/`, desigdata)
                .then(res => {
                    console.log(res)
                    GetData()
                }).catch(error => {
                    console.log(error.response)
                })
        } else {
            axiosConfig.post("/accounts/verified-profile-designations/", data_to_post, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(res => {
                    console.log(res)
                    GetData()
                    setdesigdata({})
                    setStep(1)
                }).catch(error => {
                    console.log(error.response)
                    setpopuptype("error")
                })
        }
    }
    const GetData = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/verified-profile-designations/?verified_profile=${VerifyId}`);
            console.log(response);
            settabledata(response.data.results)
        } catch (error) {
            console.log(error);
        }
    }
    const getdesignationdata = (id) => {
        // try {
        //     const response = axiosConfig.get(`/accounts/verified-profile-designations/${id}/`);
        //     console.log(response.data);
        //     setdesigdata(response.data)
        // } catch (error) {
        //     console.log(error);
        // }
    }
    const handlesteps = (step) => {
        console.log(Data.status === "requested")
        if (step === 1) { setBasicDetails(true); setProfessionalDetails(false); setStatus(false); setStep(1) }
        { VerifyId !== null && step === 2 && (() => { setBasicDetails(false); setProfessionalDetails(true); setStatus(false); setStep(2); GetData(); getRoles(); return null; })() }
        { VerifyId !== null && step === 2 && (() => { setBasicDetails(false); setProfessionalDetails(false); setStatus(true); setStep(3); return null; })() }
    }
    useEffect(() => {
        GetEditData()
        GetprofileData()
    }, []);
    return (
        <>
            <CustomTop />
            <div className="main-container">
                <Navbar />
                <div className="content-area" style={{ padding: '20px' }}>
                    <div className="verifi-text">Unlock exclusive opportunities by verifying your profile! Ensure your authenticity and get discovered by top industry professionals in the entertainment and media world.</div>
                    <div className="d-flex justify-content-center" style={{ marginBottom: '50px' }}>
                        <div style={{ position: 'relative' }} onClick={() => { handlesteps(1) }}>
                            {step >= 1 && VerifyId !== null ? (
                                <>
                                    <MdCheckCircle size={30} color="green" />
                                </>
                            ) : (
                                Basicdetails === true ? (
                                    <>
                                        <MdCheckCircleOutline size={30} color="green" />
                                    </>
                                ) : (
                                    <>
                                        <MdRadioButtonUnchecked size={30} color="green" />
                                    </>
                                )
                            )}
                            <div className="Audi-title">Basic Details</div>
                        </div>
                        <hr style={{ width: '100px', border: '1px solid green', height: '1px' }} />
                        <div style={{ position: 'relative' }} onClick={() => { handlesteps(2) }}>
                            {step >= 2 ? (
                                <>
                                    <MdCheckCircle size={30} color="green" />
                                </>
                            ) : (
                                Professionaldetails === true ? (
                                    <MdCheckCircleOutline size={30} color="green" />
                                ) : (
                                    <MdRadioButtonUnchecked size={30} color="green" />
                                )
                            )}
                            <div className="Audi-title">Verification Proofs</div>
                        </div>
                        <hr style={{ width: '100px', border: '1px solid green', height: '1px' }} />
                        <div style={{ position: 'relative' }} onClick={() => { handlesteps(3) }}>
                            {step >= 3 ? (
                                <MdCheckCircle size={30} color="green" />
                            ) : (
                                Status === true ? (
                                    <MdCheckCircleOutline size={30} color="green" />
                                ) : (
                                    <MdRadioButtonUnchecked size={30} color="green" />
                                )
                            )}
                            <div className="Audi-title">Verification Approval/Status</div>
                        </div>
                    </div>
                    {Basicdetails &&
                        <FormParser modelObject={Masters.Verification} formData={Data} formSubmit={formSubmit} error={Error} />
                    }
                    {Professionaldetails &&
                        <>
                            {VerifyId === null &&
                                <div className='desig-text'>first you fill details in basic details then only you should able to submit designation detils</div>
                            }
                            <div className="customtable-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Sr</th>
                                            <th>Designation Name</th>
                                            <th>designation union name</th>
                                            <th>designation union Proof</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tabledata.map((frame, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{frame.role?.frame_name || '1'}</td>
                                                    <td>{frame.designation_union_name || ' '}</td>
                                                    <td>
                                                        {frame.designation_union_copy ? (
                                                            <img src={frame.designation_union_copy} alt="Designation Union Copy" style={{ width: '100px', height: 'auto' }} />
                                                        ) : (
                                                            "No Image"
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {/* <MdOutlineEdit onClick={() => getdesignationdata(frame.id)} /> */}
                                                            {/* <MdOutlineDelete onClick={() => getdesignationdata(frame.id)} /> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td>{tabledata.length + 1}</td>
                                            <td>
                                                <Select
                                                    options={roles.map((res) => ({ "value": res.id, "label": res.frame_name }))}
                                                    placeholder="Select Designation"
                                                    value={roles.map(res => ({ "value": res.id, "label": res.frame_name })).find(res => res.value === Data.role)}
                                                    onChange={(selectedOption) => handleSelectChange(selectedOption, "role")}
                                                    required
                                                />
                                            </td>
                                            <td>
                                                <input type="text" className="form-control" id="validationCustom01" placeholder="Designation Union Name" name="designation_union_name" onChange={HandleChange} required />
                                            </td>
                                            <td>
                                                <input type="file" className="form-control" placeholder="Designation Union Proof" name="designation_union_copy" onChange={handleFileChange} required />
                                            </td>
                                            <td onClick={() => formSubmit1()} style={{ color: "#6A0DAD", textDecoration: "underline" }}>+ Add</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            {/* <FormParser modelObject={Masters.VerificationProofs} formData={desigdata} formSubmit={formSubmit1} /> */}
                        </>
                    }
                    {Status &&
                        <>
                            <div className="customtable-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th style={{ width: '160px' }}>Verification Status</th>
                                            <td>{StatusData?.status ? StatusData.status : "-"}</td>
                                        </tr>
                                        <tr>
                                            <th>Comments</th>
                                            <td>{StatusData?.comments ? StatusData.comments : "-"}</td>
                                        </tr>
                                        <tr>
                                            <th>Applied on</th>
                                            <td>{StatusData?.created_at ? new Date(StatusData.created_at).toLocaleDateString('en-GB').replace(/\//g, '-') : "-"}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className="verif-status">
                                <div>Verification Status : </div>
                                <h5 style={{ marginBottom: "0" }}> {StatusData?.status ? StatusData.status : "-"}</h5>
                            </div>
                            <div className="verif-status">
                                <div>Comments : </div>
                                <h5 style={{ marginBottom: "0" }}> {StatusData?.comments ? StatusData.comments : "-"}</h5>
                            </div> */}
                        </>
                    }
                </div>
                {popuptype != "" &&
                    <PopupModal popupType={popuptype} setpopupType={setpopuptype} error={Error} />
                }
            </div >
            <Footer />
        </>

    );
};

export { VerifiedPage };
