import React, { useState, useEffect } from "react";
import "./CustomTop.css";
import { useLocation, NavLink } from "react-router-dom";
import { BiAddToQueue, BiMenuAltLeft, BiSearch, BiSearchAlt2 } from "react-icons/bi";
import { MdOutlineNotifications, MdOutlinePowerSettingsNew, MdOutlinePassword, MdOutlineSettings } from "react-icons/md";
import { RiAccountCircleLine } from "react-icons/ri";
import { useAuth } from "../Utils/AuthContext";
import { useNavigate } from "react-router";
import Modal from 'react-bootstrap/Modal';
import { EditDp, Login, Signup } from "../Components/Login";
import CustomMobile from "./CustomMobile";
import { LiaUserCheckSolid } from "react-icons/lia";
import { Notifications } from "./Notifications";
import { NewPost } from "./NewPost";
import { FaCrown } from "react-icons/fa";
import { UpgradePage } from "./UpgradePage";
import { Tab, Tabs } from "react-bootstrap";
import { CraftsModal } from "./CraftsModal";
const CustomTop = () => {
    const location = useLocation();
    const Navigate = useNavigate();
    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft, socketUrl, setSocketurl, messagelabel, setMessagelabel } = useAuth();
    const [show, setShow] = useState(false);
    const [loginShow, setloginShow] = useState(false);
    const [loginView, setloginView] = useState("login");
    const handleLoginClose = () => setloginShow(false);
    const handleLoginShow = () => setloginShow(true);
    const [ProfileData, setProfileData] = useState({})
    const [isRead, setIsRead] = useState(false);
    const [page, setPage] = useState(1);
    const [AddPost, setAddPost] = useState(false)
    const setProfile = () => {
        setProfileData(JSON.parse(localStorage.getItem("profile_data")))
    }
    const ActiveData = (activeUrl) => {
        setIsLoggedIn(false);
        localStorage.setItem("activeurl", activeUrl)
    }
    const hcc_id = localStorage.getItem('hcc_id');
    const MobileLogin = () => {
        Navigate(`/profile/${hcc_id}`)
    }
    function handleLoginEvent() {
        handleLoginClose();
        setIsLoggedIn(true);
        setProfile()
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    const CheckLogin = async () => {
        if (localStorage.getItem('token')) {
            setIsLoggedIn(true)
            setProfile()
        }
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    const handleClose = () => { setShow(false); setIsRead(false) }
    const [UpgradeShow, setUpgradeShow] = useState(false)
    const [ScreenVisbile, setScreenVisbile] = useState("plans")
    const HandleUpgrade = () => {
        setUpgradeShow(true)
    }
    const handleUpgradeClose = () => {
        setUpgradeShow(false)
        setScreenVisbile("plans")
    }
    const [key, setKey] = useState('Login');
    const [profilePic, setProfilepic] = useState(false)
    const [showApply, setshowApply] = useState(false)
    let profile_data = JSON.parse(localStorage.getItem("profile_data"))
    const handleShow = () => {
        console.log(localStorage.getItem("is_24_craft"))
        if (localStorage.getItem('token')) {
            if (profile_data && profile_data.profile_pic === null || "") {
                setProfilepic(true)
            } else if (localStorage.getItem("is_24_craft") === null) {
                setshowApply(true)
            } else {
                setShow(true)
                setPage(1)
            }
        } else {
            handleLoginShow();
        }
    }
    const HandleMessage = () => {
        Navigate('/messages')
        setMessagelabel(false)
    }
    const handleprofilpicclose = () => {
        setProfilepic(false)
    }
    const handleDepartmentClose = () => {
        setshowApply(false)
    }
    const getIdFromUrl = () => {
        const path = window.location.pathname;
        const segments = path.split('/');
        const id = segments[segments.length - 1];
        console.log(path, "path", typeof (id))
        return id;
    };
    const MessagelabelIcon = () => {
        console.log(socketUrl, "messageSocket")
        if (socketUrl) {
            socketUrl.on('message', (data) => {
                console.log("New messages received", data);
                console.log(data.sender)
                if (JSON.stringify(data.sender) === getIdFromUrl()) {
                    console.log("testing")
                    setMessagelabel(false)
                } else {
                    console.log("tested")
                    setMessagelabel(true)
                }
            });
            return () => {
                socketUrl.off('newMessage');
            };
        }
    }
    useEffect(() => {
        MessagelabelIcon()
    }, [socketUrl])
    useEffect(() => {
        setProfile();
        CheckLogin();
        if (location.pathname === '/signup') {
            if (localStorage.getItem("token")) {
                Navigate("/")
                setloginView("")
                setloginShow(false)
            } else {
                console.log("test")
                setloginView("signup")
                setKey("Signup")
                // setloginShow(true)
            }
        }
    }, [])
    return (
        <>
            <div className="desktop-container">
                <div className="top-main">
                    <div className="logo" onClick={() => Navigate('/')}>
                        <img src={require('../Pages/images/hcclogo.webp')} className="logo-image" />
                    </div>
                    <div className="search" onClick={() => Navigate('/s')}>
                        <input
                            type="text"
                            placeholder="Search for Artists..."
                        />
                        <BiSearch className="option-icon" />
                    </div>
                    {isLoggedIn ?
                        <div className="options">
                            <div className="options-container">
                                <div onClick={handleShow}><img src={require("./images/bell.webp")} className="option-icon" /></div>
                                <div onClick={HandleMessage} style={{ position: "relative" }}><img src={require('../Pages/images/messageicon.webp')} className="option-icon" />{messagelabel && <div className="message-label"></div>}</div>
                                {/* {is24Craft &&
                                    <>
                                        <div onClick={(e) => { setAddPost(true); }}><BiAddToQueue className="option-icon" /></div>
                                    </>
                                } */}
                                {/* <div className="nav-item dropdown account-topnav">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={ProfileData && ProfileData.profile_pic ? ProfileData.profile_pic : require("../Components/Post/images/male.jpg")} width="40" className="profile-pic-top" />
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <a className="dropdown-item">
                                                <NavLink to={`/profile/${hcc_id}`} >My Account</NavLink>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item">
                                                <NavLink to={`/payment-history`} >Payment History</NavLink>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item">
                                                <NavLink to={`/profile/${hcc_id}`} >Change Password</NavLink>
                                            </a>
                                        </li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><NavLink to="/logout" className="dropdown-item" onClick={() => ActiveData("/logout")}>Log Out</NavLink></li>

                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        :
                        <div className="options">
                            <div className="login-text" onClick={handleLoginShow}>login</div>
                        </div>
                    }

                </div>
                {/* <div className="content">
                    {children}
                </div> */}
            </div>
            <div className="top-mobile-main">
                <div className="logo">
                    {isLoggedIn &&
                        <div class="dropdown show-only-mobile">
                            <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" style={{ border: 'none' }}>
                                <img src={require("../Components/Navbar/images/more.webp")} style={{ height: "22px", width: "28px" }} />
                            </button>
                            <ul class="dropdown-menu dropdown-margin popup-sheet">
                                <li>
                                    <NavLink to={`/profile/${hcc_id}`} className="dropdown-item"> <RiAccountCircleLine /> My Profile</NavLink>
                                </li>
                                <li>
                                    <div className="dropdown-item" onClick={HandleUpgrade}> <FaCrown color="#daa520" />{JSON.parse(localStorage.getItem("is_pro")) ? "Pro Member" : "Upgrade"}</div>
                                </li>
                                <li>
                                    <NavLink to="/shortlist" className="dropdown-item"> <LiaUserCheckSolid /> Shortlisted Profiles</NavLink>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#"><MdOutlineSettings /> Setting</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="#"><MdOutlinePassword /> Change Password</a>
                                </li>
                                <li>
                                    <NavLink to="/logout" className="dropdown-item" ><MdOutlinePowerSettingsNew /> Log Out</NavLink>
                                </li>
                                <li className="follow-us">
                                    <hr />
                                    <a class="dropdown-item" href="#" style={{ textAlign: 'center' }}>Follow Us On</a>
                                    <div className="social-align">
                                        <img src={require('../Pages/images/facebook.jpg')} className="social-icons" />
                                        <img src={require('../Pages/images/instagram.jpg')} className="social-icons" />
                                        <img src={require('../Pages/images/youtube1.jpg')} className="social-icons" />
                                        <img src={require('../Pages/images/twitter.png')} className="social-icons" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    }
                    <img src={require('../Pages/images/hcclogo.webp')} className="logo-image" />
                    <div className="d-flex" style={{ gap: '20px ' }}>
                        <div onClick={handleShow}><img src={require("./images/bell.webp")} className="option-icon" /></div>
                        <div onClick={HandleMessage} style={{ position: "relative" }}><img src={require('../Pages/images/messageicon.webp')} className="option-icon" />{messagelabel && <div className="message-label"></div>}</div>
                    </div>
                </div>
                <CustomMobile />
            </div>
            <Notifications show={show} setShow={setShow} handleClose={handleClose} ProfileData={ProfileData} isRead={isRead} setIsRead={setIsRead} setPage={setPage} page={page} />
            <Modal size={loginView == "login" ? "md" : "lg"} aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                <Modal.Header closeButton style={{ paddingBottom: "0px" }}>
                    {/* <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="modal-tabs"
                    >
                        <Tab eventKey="Login" title="Login"></Tab>
                        <Tab eventKey="Signup" title="Signup"></Tab>
                    </Tabs> */}
                    <div className="login-text-type">{loginView === "login" ? "Welcome back !" : "Create Your Account"}</div>
                </Modal.Header>
                <Modal.Body>
                    {/* {key === "Login" ? (
                        <Login handleLoginEvent={handleLoginEvent} />
                    ) : (
                        <Signup handleLoginEvent={handleLoginEvent} />
                    )} */}
                    {loginView == "login" ? (
                        <>
                            <Login handleLoginEvent={handleLoginEvent} />
                            <div className="switchtag" onClick={() => setloginView("signup")} ><span>New user?</span> Sign Up</div>
                        </>
                    ) : (
                        <>
                            <Signup handleLoginEvent={handleLoginEvent} />
                            <div className="switchtag" onClick={() => setloginView("login")} ><span>Already have an account?</span> Click to Login</div>
                            {/* <a href="#" className="switchtag" onClick={() => setloginView("login")} >Already have an account ? Click to Login</a> */}
                        </>
                    )}
                </Modal.Body>
                {/* <Modal.Footer className="loginfooter">
                    <p>By continuing, you agree to our</p>
                    <a href="/terms-conditions" target="_blank">Terms of service</a>
                    <a href="/privacy-policy" target="_blank">Privacy policy</a>
                </Modal.Footer> */}
            </Modal>
            {AddPost &&
                <NewPost AddPost={AddPost} setAddPost={setAddPost} />
            }
            {UpgradeShow &&
                <UpgradePage UpgradeShow={UpgradeShow} setUpgradeShow={setUpgradeShow} ScreenVisbile={ScreenVisbile} setScreenVisbile={setScreenVisbile} handleUpgradeClose={handleUpgradeClose} />
            }
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={profilePic} onHide={handleprofilpicclose} className="edit-profile-popup">
                <Modal.Header closeButton>
                    <Modal.Title>Add your profile to get started</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditDp handleLoginEvent={handleprofilpicclose} showApply={showApply} setshowApply={setshowApply} />
                </Modal.Body>
            </Modal>
            {showApply &&
                <CraftsModal showApply={showApply} setshowApply={setshowApply} handleClose={handleDepartmentClose} />
            }
        </>
    )
}

export default CustomTop;