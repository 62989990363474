import React, { useState, useEffect } from 'react';
import axiosConfig from "../../Service/axiosConfig"
import { useDispatch } from "react-redux";
import { addWishlist, addShortlist, addFollower } from '../../redux/Slices/cartSlice';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../Utils/AuthContext";
import "./Login.css"

const Login = ({ handleLoginEvent }) => {
  const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft, socketUrl, setSocketurl } = useAuth();
  const [Data, setData] = useState({})
  const [Error, setError] = useState("")
  const [formView, setformView] = useState("loginform")
  const [OtpSent, setOtpSent] = useState(false)
  const [OtpVerified, setOtpVerified] = useState(false)
  const [visible, SetVisable] = useState(false)
  const inputType = visible ? "text" : "password"
  let Navigate = useNavigate()
  const RefreshLogin = async () => {
    try {
      const response = await axiosConfig(`/accounts/api/refresh_login/`)
      console.log(response)
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('profile_data', JSON.stringify(response.data.profile_data));
      localStorage.setItem('is_pro', response.data.is_pro ? true : false);
      if (response.data.is_24_craft) {
        localStorage.setItem('is_24_craft', response.data.is_24_craft);
        localStorage.setItem('frames_24', response.data.frames_24);
        localStorage.setItem('hcc_id', response.data.hcc_id);
      }
      handleLoginEvent();
      window.location.reload();
    }
    catch (error) {
      console.log(error)
    }
  }
  const OnSubmit = (e) => {
    e.preventDefault()
    axiosConfig.post("/accounts/api/login/", Data).then(res => {
      console.log(res)
      localStorage.setItem('token', res.data.token);
      setUserId(res.data.profile_data.id)
      if (res.data.is_24_craft) {
        localStorage.setItem('is_24_craft', res.data.is_24_craft);
        localStorage.setItem('frames_24', res.data.frames_24);
      }
      localStorage.setItem('hcc_id', res.data.hcc_id);
      localStorage.setItem('profile_data', JSON.stringify(res.data.profile_data));
      localStorage.setItem('is_pro', res.data.is_pro ? true : false);
      localStorage.setItem('socketId', socketUrl?.id);
      handleLoginEvent()
      if (res.data.profile_data.profile_pic === null || "") {
        Navigate(`/profile/${res.data.hcc_id}`)
      }
    }).catch(error => {
      console.log(error)
      console.log(error.response?.data.error)
      setError(error.response?.data.error)
    })
  };
  const OnForgetSubmit = (e) => {
    e.preventDefault()
    setError("")
    if (OtpSent) {
      axiosConfig.post("/accounts/api/reset_password/", Data).then(res => {
        if (res.data == "OTP Verified") {
          setOtpVerified(true)
        } else if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          console.log("res.data.profile_data.id", res.data.profile_data.id)
          setUserId(res.data.profile_data.id)
          if (res.data.is_24_craft) {
            localStorage.setItem('is_24_craft', res.data.is_24_craft);
            localStorage.setItem('frames_24', res.data.frames_24);
            localStorage.setItem('hcc_id', res.data.hcc_id);
          }
          localStorage.setItem('profile_data', JSON.stringify(res.data.profile_data));
          localStorage.setItem('is_pro', res.data.is_pro ? true : false);
          handleLoginEvent()
        }
      }).catch(error => {
        console.log(error.response.data.error)
        setError(error.response.data.error)
      })
    } else {

      axiosConfig.post("/accounts/api/forgetpassword/", Data).then(res => {
        if (res.data == "otp sent") {
          setOtpSent(true)
        }
      }).catch(error => {
        console.log(error.response.data.error)
        setError(error.response.data.error)
      })
    }


  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const name = e.target.name
    const value = e.target.value
    console.log(name, value)
    setData({ ...Data, [name]: value })
  }

  const [userId, setUserId] = useState()
  const dispatch = useDispatch();
  const [isDeleted, setIsDeleted] = useState(false)
  const GetData = async () => {
    console.log("inside get data")
    try {
      const response = await axiosConfig.get(`/accounts/likes/?user=${userId}&page_size=1000`)
      response.data.results.map((item) => {
        dispatch(addWishlist({ productId: item.modelimage, wish_id: item.id }));
      })
      setIsDeleted(false)
    }
    catch (error) {
      console.log(error)
    }
  }
  const GetShortList = async () => {
    try {
      const response = await axiosConfig.get(`/accounts/shortlists/?page_size=1000&user=${userId}`)
      response.data.results.map((item) => {
        dispatch(addShortlist({ ShortlistId: item.profile.id, short_id: item.id }));
      })
      setIsDeleted(false)
    }
    catch (error) {
      console.log(error)
    }
  }
  const GetFollowers = async () => {
    try {
      const response = await axiosConfig.get(`/accounts/follows/?page_size=1000&user=${userId}`)
      response.data.results.map((item) => {
        dispatch(addFollower({ FollowId: item.profile.id, Follow_id: item.id }));
      })
      setIsDeleted(false)
    }
    catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (userId) {
      GetData();
      GetShortList();
      GetFollowers();
      // RefreshLogin()
    }
  }, [isDeleted, userId])

  return (
    <div className="container">
      {formView == "loginform" ? <>
        <form className='login-from' onSubmit={OnSubmit} >
          {Error &&
            <div className="alert alert-danger">
              <strong>{Error}!</strong>
            </div>
          }
          <div class="material-textfield">
            <input placeholder="UserName/Email" id="username" type="text" name="username" onChange={handleInputChange} required />
            <label htmlFor="username">UserName/Email</label>
          </div>
          <div className="material-textfield">
            <input type="password" id="password" placeholder="password" name="password" onChange={handleInputChange} required />
            <label htmlFor="password" >Password</label>
          </div>
          {/* <div className="form-group has-icon">
            <label htmlFor="validationCustom01">Password:</label>
            <input
              type={inputType}
              className="form-control"
              placeholder="password"
              name="password"
              onChange={handleInputChange}
            />
            {visible ?
              <img className="inputicon1" src={require('../../Pages/images/show.png')} alt="password" onClick={() => SetVisable(false)} /> :
              <img className="inputicon" src={require('../StaffLogin/images/eye.jpg')} alt="password" onClick={() => SetVisable(true)} />
            }
          </div> */}
          <a href="#" className="forgot-pwd" onClick={() => setformView("forgotview")} >Forgot your password ?</a>
          <button type="submit" className="btn btn-primary btn-block login-btn">
            Login
          </button>
        </form>
      </> :
        <>

          <form className='login-from' onSubmit={OnForgetSubmit} >
            {Error &&
              <div className="alert alert-danger">
                <strong>{Error}!</strong>
              </div>
            }

            <div className="form-group">
              <label>Email:</label>
              <input
                type="text"
                className="form-control"
                placeholder="Email"
                name="username"
                onChange={handleInputChange}
                readOnly={OtpSent}
              />
            </div>
            {OtpSent &&
              <>
                {!OtpVerified &&
                  <div className="alert alert-success">
                    <strong>OTP sent to your Email!</strong>
                  </div>
                }
                <div className="form-group">
                  <label>OTP:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="OTP"
                    name="otp"
                    onChange={handleInputChange}
                    readOnly={OtpVerified}
                  />
                </div>
                {OtpVerified &&
                  <>
                    <div className="alert alert-success">
                      <strong>OTP Verified Successfully!</strong>
                    </div>
                    <div className="form-group">
                      <label>Enter New Password:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter New Password"
                        name="password"
                        onChange={handleInputChange}
                      />
                    </div>
                  </>
                }
              </>
            }

            <a href="#" className="forgot-pwd" onClick={() => setformView("loginform")} >know password ? login</a>
            <button type="submit" className="btn btn-primary btn-block">
              {OtpVerified ? 'Set New Password & Login' :
                <>
                  {OtpSent ? 'Verify OTp' : 'Send OTP'}
                </>
              }
            </button>
          </form>
        </>}
    </div>
  );
};

export { Login };
