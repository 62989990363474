import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig"
import { ExploreGrid } from "./exploregrid";
import { useNavigate, useSearchParams, NavLink } from "react-router-dom";
import { useAuth } from '../Utils/AuthContext';
import { Footer, Navbar } from "../Components";
import CustomTop from "./CustomTop";

const ShortlistPage = () => {
    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft } = useAuth();
    const Navigate = useNavigate();
    const [Data, setData] = useState([])
    const [loading, setLoading] = useState("")
    const [loading2, setLoading2] = useState("")
    const [isDeleted, setIsDeleted] = useState(false)
    const [ProfileData, setProfileData] = useState({})
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [totalPages, settotalPages] = useState()
    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    const setProfile = () => {
        setProfileData(JSON.parse(localStorage.getItem("profile_data")))
    }
    let page = 1;
    const GetData = async () => {
        let profile_data = JSON.parse(localStorage.getItem('profile_data'))
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/shortlists/?page_size=30&user=${profile_data.id}&is_suspended=false`)
            settotalPages(Math.ceil(responce.data.count / 30));
            const profiles = responce.data.results.map(item => {
                const profile = item.profile;
                // profile.full_name = `${profile.first_name}`;
                return profile
            });
            console.log(profiles)
            setData(profiles)
            setLoading(false)
            setIsDeleted(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const handleInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                page = page + 1;
                console.log(page)
                if (page <= totalPages && !loading2) {
                    setLoading2(true);
                    let profile_data = JSON.parse(localStorage.getItem('profile_data'))
                    const resp = await axiosConfig.get(`/accounts/shortlists/?page=${page}&page_size=30&user=${profile_data.id}`);
                    const profiles = resp.data.results.map(item => {
                        const profile = item.profile;
                        profile.full_name = `${profile.first_name}`;
                        return profile
                    });
                    console.log(profiles)
                    setData((Data) => [...Data, ...profiles])
                    setLoading2(false);
                }
            }
        } catch (error) {
            console.log(error);
            setLoading2(false);
        }
    };
    console.log(Data, "Data")
    useEffect(() => {
        GetData();
        setProfile();
    }, [])
    useEffect(() => {
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        }
    }, [totalPages])
    return (
        <>
            <CustomTop />
            <div className="search-screen-container search-page">
                <Navbar />
                <div className="content-area shortlist_contentarea">
                    <ExploreGrid Data={Data} loading={loading} loading2={loading2} />
                </div>
            </div>
            <Footer />
        </>
    )
}
export { ShortlistPage };