import React, { createContext, useState, useContext, useEffect } from 'react';
import io from 'socket.io-client';
import websocketUrl from "../Service/WebsocketUrl";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [is24Craft, setIs24Craft] = useState(false);
  const [messagelabel, setMessagelabel] = useState(false);
  const [socketUrl, setSocketurl] = useState(null);
  let profile_data = JSON.parse(localStorage.getItem("profile_data"))
  const token = localStorage.getItem("token");
  const storedSocketId = localStorage.getItem("socketId");

  const connectSocket = () => {
    console.log("Stored socketId:", storedSocketId);
    const socket = io(websocketUrl, {
      auth: {
        token,
      },
    });
    socket.on('connect', () => {
      console.log("Connected to WebSocket server:", socket.id);
      if (token) {
        socket.emit('register', profile_data?.id);
      }
      if (socket.id !== storedSocketId) {
        localStorage.setItem('socketId', socket.id);
        console.log("New socketId stored:", socket.id);
      }
      setSocketurl(socket); // Save the socket instance
    });
    socket?.on('message', (data) => {
      console.log("message received", data)
      setMessagelabel(true)
    });
    socket.on('reconnect', () => {
      console.log("Reconnected to WebSocket server:", socket.id);
      if (token) {
        socket.emit('register', token);
      }

      if (socket.id !== storedSocketId) {
        localStorage.setItem('socketId', socket.id);
        console.log("Updated socketId after reconnect:", socket.id);
      }
    });
    socket.on('disconnect', () => {
      console.log("Socket disconnected:", socket.id);
    });
    socket.on('connect_error', (err) => {
      console.error("WebSocket connection error:", err);
    });
  };

  useEffect(() => {
    if (token) {
      connectSocket();
    }
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn, setIsLoggedIn, is24Craft, setIs24Craft, socketUrl, setSocketurl, messagelabel, setMessagelabel
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
