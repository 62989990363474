import React, { useState, useEffect } from "react";
import { Footer, Navbar } from "../Components";
import CustomTop from "./CustomTop";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuditionScreen } from "./AuditionScreen";
import { AudiRequire } from "./AudiRequire";

const AuditionPage = () => {
    return (
        <>
            <CustomTop />
            <div className="search-screen-container search-page mobileauditions_bacgroung">
                <Navbar />
                <div className="content-area">
                    {/* <AuditionScreen /> */}
                    <AudiRequire />
                </div>
            </div >
            <Footer />
        </>
    );
}

export { AuditionPage };
