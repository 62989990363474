import React, { useEffect, useState } from 'react';
import axiosConfig from "../Service/axiosConfig";
import { useAuth } from '../Utils/AuthContext';

const userId = 1234; // Replace with the logged-in user's ID
function MessageComponent() {
  // const { isLoggedIn, is24Craft, socketUrl } = useAuth();
  // const [message, setMessage] = useState('');
  // const [chat, setChat] = useState([]);
  // const receiverId = 4565; // Replace with the intended receiver's ID

  // useEffect(() => {
  //   // Register the logged-in user's ID with the server
  //   socket.emit('register', userId);

  //   // Fetch previous messages between the logged-in user and the receiver
  //   const fetchMessages = async () => {
  //     try {
  //       const response = await axiosConfig.get(`/messagesapp/messages/get_messages/?receiver=${receiverId}`);
  //       setChat(response.data);
  //     } catch (error) {
  //       console.error("Error fetching messages:", error);
  //     }
  //   };

  //   fetchMessages();

  //   // Listen for incoming messages
  //   socket.on('message', (data) => {
  //     setChat((prevChat) => [...prevChat, data]);
  //   });

  //   return () => socket.off('message'); // Clean up on unmount
  // }, [receiverId]);

  // const sendMessage = async () => {
  //   if (message) {
  //     // Send the message through WebSocket
  //     socket.emit('message', { sender: userId, receiver: receiverId, content: message });

  //     // Save message to the server via REST API
  //     const formData = new FormData();
  //     formData.append('receiver', receiverId);
  //     formData.append('content', message);
  //     axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
  //     try {
  //       await axiosConfig.post('/messagesapp/messages/send_message/', formData);
  //     } catch (error) {
  //       console.error(error);
  //     }

  //     setMessage(''); // Clear input field
  //   }
  // };

  return (
    <div style={{ padding: '20px' }}>
      {/* <h2>WebSocket Chat</h2>
      <div>
        {chat.map((msg, index) => (
          <div key={index}>
            <strong>{msg.sender === receiverId ? 'Other' : 'You'}:</strong> {msg.content}
          </div>
        ))}
      </div>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type a message..."
      />
      <button onClick={sendMessage}>Send</button> */}
    </div>
  );
}

export default MessageComponent;
