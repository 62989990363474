import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { clearCart } from '../redux/Slices/cartSlice';
import { useAuth } from "../Utils/AuthContext";

const Logout = () => {
    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft, socketUrl, setSocketurl } = useAuth();
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        setIsLoggedIn(false);
        localStorage.clear();
        dispatch(clearCart());
        setIs24Craft(false);
        socketUrl?.disconnect()
        return Navigate("/")
    }, [])
}

export default Logout;